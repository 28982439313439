.tile--letter {
  position: relative;
}

.tile--letter figure {
  position: absolute;
  height: 100%;
  width: 100%;
  will-change: opacity, transform;
  position: absolute;
}

.tile--letter svg {
  display: block;
  height: 129%;
  width: auto;
}

.tile--letter.tile--N svg {
  height: 122%;
  @media screen and (min-width: $desktop) { height: 125%; }
}

html:not(.loaded) .tile--B figure {
  animation: slideDown $animation-duration both ease-out;
  @media screen and (min-width: $tablet) { animation-name: slideDown; }
  @media screen and (min-width: $desktop) { animation-name: slideRight; }
}
html:not(.loaded) .tile--O figure {
  animation: slideLeft $animation-duration both ease-out;
  @media screen and (min-width: $tablet) { animation-name: slideLeft; }
  @media screen and (min-width: $desktop) { animation-name: slideUp; }
}
html:not(.loaded) .tile--N figure {
  animation: slideRight $animation-duration both ease-out;
  @media screen and (min-width: $tablet) { animation-name: slideRight; }
  @media screen and (min-width: $desktop) { animation-name: slideDown; }
}
html:not(.loaded) .tile--D figure {
  animation: slideUp $animation-duration both ease-out;
  @media screen and (min-width: $tablet) { animation-name: slideUp; }
  @media screen and (min-width: $desktop) { animation-name: slideLeft; }
}


.tile--B figure {
  left: 4%;
  top: -35%;
}

.tile--O figure {
  left: 15%;
  top: -20%;

  @media screen and (min-width: $tablet) {
    left: 15%;
    top: -20%;
  }

  @media screen and (min-width: $desktop) {
    top: -6.5%;
    left: 7%;
  }
}

.tile--N figure {
  left: -19%;
  top: -25%;

  @media screen and (min-width: $tablet) {
    left: -24%;
    top: -10%;
  }

  @media screen and (min-width: $desktop) {
    left: 15%;
    top: -40%;
  }
}

.tile--D figure {
  left: -15%;
  top: 20%;

  @media screen and (min-width: $tablet) {
    left: -15%;
    top: 10%;
  }

  @media screen and (min-width: $desktop) {
    top: 7%;
    left: 3%;
  }
}
