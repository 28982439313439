@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes slideDown {
  from {
    transform: translateY(-1 * $slide-length);
    opacity:  0.5;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY($slide-length);
    opacity:  0.5;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideLeft {
  from {
    transform: translateX($slide-length);
    opacity:  0.5;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-1 * $slide-length);
    opacity:  0.5;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}