.tile--content {
  background: color('tile-bg');
  color:      color('tile-text');
  &.tile--intro {
    background: color('main-tile-bg');
    color:      color('main-tile-text');
  }
}

.tile--content {
  padding: 1rem;
  line-height: 1.3;
  
  font-size: 26px;
  @media screen and (max-width: 350px) {
    font-size: 24px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 28px;
  }

  h1 {
    display: inline;
    font-weight: bold;
  }

  strong {
    font-weight: bold;
  }
  
  p + p {
    margin-top: 1em;
  }

  a {
    will-change: border-bottom-color;
    text-decoration: none;
    border-bottom: solid 2px rgba(color('tile-text'), 0.3);
    color: inherit;
    transition: border-bottom-color 100ms ease-in-out;
    &:hover {
      border-bottom-color: color('tile-text');
    }
  }
  
}

.tile--intro > * {
  display: inline;
}

.tile--content > * {
  animation: $animation-duration ease-out both fadeIn;
}

.tile--tickets form {

  margin-top: .15em;

  input[type='email'] {
    display: block;
    appearance: none;
    border: 0;
    border-radius: 0;
    color: inherit;
    background: none;
    font-family: inherit;
    font-size: 1em;
    line-height: normal;
    width: 100%;
    border-bottom: solid 2px rgba(color('tile-text'), 0.65);
    font-weight: bold;
    padding: .25em 0;

    &:focus {
      outline: none;
      border-bottom-color: inherit;
    }

    &::placeholder {
      color: rgba(color('tile-text'), 0.5);
      font-weight: normal;
    }

  }

  button {
    margin-top: 1em;
    display: block;
    width: 100%;
    transition: all 150ms ease-in-out;
    opacity: 1;
    appearance: none;
    border: none;
    background: #2D0B5E;
    color: white;
    font-family: inherit;
    font-size: inherit;
    line-height: 1;
    padding: .375em .5em;
    border-radius: 3px;
    text-align: left;
    display: flex;
    cursor: pointer;
    will-change: opacity, transform;
    

    &:after {
      content: '→';
      margin-left: auto;
      line-height: 1;
      transform: translateY(-.076923077em);
    }

    &:hover,
    &:focus {
      outline: none;
      background: #000;
    }
  }

  // Hide button if field is empty and unfocused
  input:placeholder-shown:not(:focus) + button {
    opacity: 0;
    transform: translateY(.25em);
  }
  
  
}

.tile--credit {

  display: flex;

  text-align: right;
  @media screen and (min-width: $tablet)  { text-align: left; }
  @media screen and (min-width: $desktop) { text-align: right; }

  .tile__wrapper {
    flex: 1;
    margin-top: auto;
  }
}