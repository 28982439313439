$tile-min-width: 290px;
$tile-max-width: 350px;

$site-padding: 20px;

$tablet:  700px;
$desktop: 1200px;

$slide-length: 20%;
$animation-duration: 1.5s;


$colors: (
  'tile-bg':        #3EE6CC,
  'tile-text':      #303957,
  'main-tile-bg':   HSL(265, 70%, 45%),
  'main-tile-text': #FFF
);
@function color($color) {
  @return map-get($colors, $color);
}

@function strip-units($number) {
  @return $number / ($number * 0 + 1);
 }

@function em($pxval, $base) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return ($pxval / $base) * 1em;
}
